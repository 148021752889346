<template>
  <h1>Kasboek</h1>

  <div class="py-4 inline-flex flex-col">
    <h2 class="bg-dashboard-dark text-white mb-0 px-2 py-1">
      Filter
    </h2>
    <div class="flex flex-row gap-4 px-2 mt-2">
      <div>
        <label for="">Year</label>
        <UISelectMinMax
          v-model="filters.year"
          min="2018"
          :max="new Date().getFullYear()"
        />
      </div>
      <div>
        <label for="">Month</label>
        <select id="" v-model="filters.month" name="">
          <option v-for="n in 12" :key="n" :value="n">
            {{ capitalize(monthToString(n)) }}
          </option>
        </select>
      </div>
      <div>
        <label for="">Kasboek type</label>
        <select id="" v-model="filters.type" name="">
          <option value="ZONDER_FACTUUR">
            Zonder factuur
          </option>
          <option value="OP_FACTUUR">
            Op factuur
          </option>
          <option value="SHOP_BETALINGEN">
            Shop betalingen
          </option>
          <option value="TEGEMOEDKOMINGEN">
            Tegemoedkomingen
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="clear-both"></div>

  <div class="py-4 inline-flex flex-col">
    <h2 class="bg-dashboard-dark text-white mb-0 px-2 py-1">
      {{ capitalize(monthToString(filters.month)) }} {{ filters.year }}
    </h2>
    <div class="flex flex-col lg:flex-row gap-2 align-between content-between justify-between mt-2">
      <ul class="flex flex-col">
        <li>
          <strong> Totaal <span class="text-green-600">betaald</span> </strong>:
          <span>&euro;&nbsp;{{ formatPrice(totals.betaald) }}</span>
        </li>
        <li>
          <strong> Totaal <span class="text-yellow-600">terugbetaling (mollie)</span> </strong>:
          <span>&euro;&nbsp;{{ formatPrice(totals.terugbetaling_mollie) }}</span>
        </li>
        <li>
          <strong>Totaal <span class="text-yellow-600">terugbetaling (overschrijving)</span> </strong>:
          <span>&euro;&nbsp;{{ formatPrice(totals.terugbetaling_overschrijving) }}</span>
        </li>
        <li>
          <strong> Totaal <span class="text-red-600">onbetaald</span> </strong>:
          <span>&euro;&nbsp;{{ formatPrice(totals.onbetaald) }}</span>
        </li>
      </ul>
      <ul class="flex flex-col">
        <li>
          <strong>Totaal <span class="text-green-600">cash</span> </strong>:
          <span>&euro;&nbsp;{{ formatPrice(totals.cash) }}</span>
        </li>
        <li>
          <strong>Totaal <span class="text-green-600">mollie</span> </strong>:
          <span>&euro;&nbsp;{{ formatPrice(totals.mollie) }}</span>
        </li>
        <li>
          <strong>Totaal <span class="text-green-600">overschrijving</span> </strong>:
          <span>&euro;&nbsp;{{ formatPrice(totals.overschrijving) }}</span>
        </li>
        <li>
          <strong>Totaal <span class="text-green-600">betaalterminal</span> </strong>:
          <span>&euro;&nbsp;{{ formatPrice(totals.betaalterminal) }}</span>
        </li>
      </ul>
    </div>
  </div>

  <UITableResponsive
    :items="list"
    :headers="Object.keys(list?.length ? list[0] : {})"
    class="text-center"
    :suffix-headers="['Acties']"
  >
    <template #colgroup>
      <colgroup v-for="(item, index) in [list[0]]" :key="index">
        <col v-if="item.dag" class="w-16">
        <col v-if="item.onbetaald" class="w-0">
        <col v-if="item.terugbetaling_mollie" class="w-0">
        <col v-if="item.terugbetaling_overschrijving" class="w-64">
        <col v-if="item.betaald" class="w-32">
        <col v-if="item.cash" class="w-32">
        <col v-if="item.overschrijving" class="w-0">
        <col v-if="item.betaalterminal" class="w-0 text-center">
        <col v-if="item.mollie" class="w-32 text-center">
        <col class="text-right">
      </colgroup>
    </template>
    <template #item-onbetaald="{ item }">
      &euro;&nbsp;{{ formatPrice(item.onbetaald) }}
    </template>
    <template #item-terugbetaling_mollie="{ item }">
      &euro;&nbsp;{{ formatPrice(item.terugbetaling_mollie) }}
    </template>
    <template #item-terugbetaling_overschrijving="{ item }">
      &euro;&nbsp;{{ formatPrice(item.terugbetaling_overschrijving) }}
    </template>
    <template #item-betaald="{ item }">
      &euro;&nbsp;{{ formatPrice(item.betaald) }}
    </template>
    <template #item-cash="{ item }">
      &euro;&nbsp;{{ formatPrice(item.cash) }}
    </template>
    <template #item-overschrijving="{ item }">
      &euro;&nbsp;{{ formatPrice(item.overschrijving) }}
    </template>
    <template #item-betaalterminal="{ item }">
      &euro;&nbsp;{{ formatPrice(item.betaalterminal) }}
    </template>
    <template #item-mollie="{ item }">
      &euro;&nbsp;{{ formatPrice(item.mollie) }}
    </template>
    <template #item-Acties="{ item }">
      <router-link
        v-if="store.getters.hasMasterPermission"
        :to="`/administratie/betalingen?date=${dateString(`${filters.year}-${filters.month}-${item.dag}`)}`"
        class="mr-2 btn info small"
      >
        <i class="fas fa-euro-sign text-green-400"></i>
        Bekijk betalingen
      </router-link>
      <router-link
        v-if="store.getters.hasMasterPermission"
        :to="`/administratie/betalingen?date_terugbetaald=${dateString(`${filters.year}-${filters.month}-${item.dag}`)}`"
        class="mr-2 btn info small"
      >
        <i class="fas fa-euro-sign text-red-400"></i>
        Bekijk terugbetalingen
      </router-link>
      <router-link
        :to="{ name: 'Kalender', query: { date: dateString(`${filters.year}-${filters.month}-${item.dag}`) } }"
        class="mr-2 btn info small"
      >
        <i class="fas fa-calendar-alt"></i>
        Bekijk kalender
      </router-link>
    </template>
  </UITableResponsive>

  <div v-if="!store.getters.isKantoorPermission" class="py-4 inline-flex flex-col">
    <h2 class="bg-dashboard-dark text-white mb-0 px-2 py-1">
      Downloaden
    </h2>
    <div class="flex flex-col lg:flex-row gap-2 align-between content-between justify-between mt-2">
      <select id="" v-model="downloadType" name="">
        <option v-for="option in downloadOptions" :key="option.value" :value="option.value">
          {{ option.label }}
        </option>
      </select>
      <button class="btn green" @click="onDownload">
        Downloaden
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, ref, watch } from 'vue'
import { useStore } from 'vuex'
import clone from 'just-clone'

import UISelectMinMax from '@/components/UI/Select/MinMax.vue'
import UITableResponsive from '@/components/UI/Table/Responsive'

import { dateString, monthToString } from '@/functions/formatDate'
import { formatPrice } from '@/functions/formatNumber'
import { capitalize } from '@/functions/formatText'

const axios = inject('axios')
const store = useStore()

const list = ref([])
const totals = ref({})
const filters = ref({
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
  type: 'ZONDER_FACTUUR',
})

const today = new Date()
const downloadType = ref('month')

const resetAndGetData = () => {
  list.value = []
  downloadType.value = 'month'
  getData(null, true)
}

watch(filters.value, resetAndGetData)

const getData = async (_params = null, setVm = true) => {
  const params = clone(_params || filters.value)
  let endpoint = '/api/dashboard/kasboek/list'

  if (params.type === 'SHOP_BETALINGEN') endpoint = '/api/dashboard/kasboek/list-shop'
  else if (params.type === 'TEGEMOEDKOMINGEN') endpoint = '/api/dashboard/kasboek/list-tegemoedkomingen'
  else if (params.type === 'ZONDER_FACTUUR') params.is_factuur = false
  else if (params.type === 'OP_FACTUUR') params.is_factuur = true

  delete params.type

  const { data } = await axios.get(endpoint, { params })

  if (setVm && data.list) list.value = data.list
  if (setVm && data.totals) totals.value = data.totals

  return data
}

getData(null, true)

// ----- Download -----

const downloadOptions = computed(() => {
  const options = []
  const thisYear = today.getFullYear()
  for (let n = 0; n <= thisYear - 2020; n += 1) {
    const year = 2020 + n
    let quarters = 4
    if (year === thisYear) {
      quarters = Math.ceil((today.getMonth() + 1) / 3) // 3 maanden in kwartaal
    }
    for (let q = 1; q <= quarters; q += 1) {
      options.push({
        value: `Q${q}-${year}`,
        label: `Q${q}-${year} ${q === quarters && year === thisYear ? '(In progress)' : ''}`,
      })
    }
  }
  options.push({
    value: 'month',
    label: `Maand ${filters.value.month > 9 ? filters.value.month : `0${filters.value.month}`}/${filters.value.year}`,
  })
  return options.reverse()
})

const csvData = el => [
  el.dag,
  el.onbetaald,
  el.terugbetaling_mollie,
  el.terugbetaling_overschrijving,
  el.betaald,
  el.betaalterminal,
  el.cash,
  el.mollie,
  el.overschrijving,
  el.factuur,
]

const downloadCSV = (list, name = null) => {
  if (!list?.length) {
    return
  }

  const rows = list.map(el => csvData(el)
    .map((el, index) =>
      index === 0
        ? el
        : Number(el || 0)
            .toFixed(2)
            .replace('.', ','),
    )
    .join(';'),
  )

  const firstData = csvData(list[0])

  rows.unshift(
    [
      'Dag',
      'Onbetaald',
      'Terugbetaling (via mollie)',
      'Terugbetaling (via overschrijving)',
      'Betaald (Totaal)',
      'Betaald (Betaalterminal)',
      'Betaald (Cash)',
      'Betaald (Mollie)',
      'Betaald (Overschrijving)',
      'Betaald (Via factuur betaald)',
    ]
      .filter((_, index) => firstData[index] !== undefined)
      .join(';'),
  )

  const csvContent = `data:text/csv;charset=utf-8,${rows.join('\r\n')}`
  const encodedUri = encodeURI(csvContent)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', `${name}.csv`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const onDownload = async () => {
  const paramsArray = []
  if (downloadType.value === 'month') {
    paramsArray.push({ month: filters.value.month, year: filters.value.year, type: 'ZONDER_FACTUUR' })
    paramsArray.push({ month: filters.value.month, year: filters.value.year, type: 'OP_FACTUUR' })
    paramsArray.push({ month: filters.value.month, year: filters.value.year, type: 'SHOP_BETALINGEN' })
  } else {
    const typeParts = downloadType.value.replace('Q', '').split('-')
    const quarter = Number(typeParts[0])
    const year = Number(typeParts[1])
    if (quarter > 0 && year >= 2020) {
      paramsArray.push({ month: quarter * 3 - 2, year, type: 'ZONDER_FACTUUR' })
      paramsArray.push({ month: quarter * 3 - 1, year, type: 'ZONDER_FACTUUR' })
      paramsArray.push({ month: quarter * 3, year, type: 'ZONDER_FACTUUR' })
      paramsArray.push({ month: quarter * 3 - 2, year, type: 'OP_FACTUUR' })
      paramsArray.push({ month: quarter * 3 - 1, year, type: 'OP_FACTUUR' })
      paramsArray.push({ month: quarter * 3, year, type: 'OP_FACTUUR' })
      paramsArray.push({ month: quarter * 3 - 2, year, type: 'SHOP_BETALINGEN' })
      paramsArray.push({ month: quarter * 3 - 1, year, type: 'SHOP_BETALINGEN' })
      paramsArray.push({ month: quarter * 3, year, type: 'SHOP_BETALINGEN' })
    }
  }
  if (!paramsArray.length) return
  const results = await Promise.all(paramsArray.map(params => getData(params, false)))
  return results.map(({ list }, index) => {
    const data = paramsArray[index]
    data.month = data.month > 9 ? data.month : `0${data.month}`
    const suffix = ({
      ZONDER_FACTUUR: 'particulier',
      OP_FACTUUR: 'factuur',
      SHOP_BETALINGEN: 'shop',
      TEGEMOEDKOMINGEN: 'tegemoedkomingen',
    })[data.type]
    return downloadCSV(list, `kasboek-${data.year}-${data.month}-${suffix}`)
  })
}
</script>

<style lang="scss" scoped>
ul {
  border-collapse: collapse;
  @apply border border-dashboard-dark;
}
ul li {
  @apply border border-dashboard-dark;
  @apply px-2 py-1 pr-4;
  border-collapse: collapse;
}
</style>
